import React from "react";
import { domToReact } from "html-react-parser";

const CustomBreadCrumbs = (props) => {
  const {
    domNode,
    options
  } = props;
  const Tag = domNode.name;
  const className = domNode.attribs.class;
  const style = {};
  (domNode.attribs.style || "").split(";").filter(e => e.length).forEach(e => {
    e = e.split(":").map(e => e.trim());
    const key = e[0].replace(/-[a-z]/g, g => g[1].toUpperCase());
    const value = e.slice(1).join(":");
    style[key] = value;
  });
  delete domNode.attribs.class;
  delete domNode.attribs.style;
  return <>
    <Tag { ...domNode.attribs } style={ style } className={ className }>
      { domToReact(domNode.children, options) }
    </Tag>
    { props.breadcrumbs }
  </>;
};

export default CustomBreadCrumbs;
